import { useEffect } from 'react'
import 'antd/lib/button/style/css'
import 'antd/lib/input/style/css'
import 'antd/lib/select/style/css'
import 'antd/lib/list/style/css'
import 'antd/lib/form/style/css'
import 'antd/lib/card/style/css'
import 'antd/lib/layout/style/css'

const StartupsPage = () => {
    useEffect(() => {
        if (window) {
            window.location.href = '/pricing#startup-plan'
        }
    }, [])
    return null
}

export default StartupsPage
